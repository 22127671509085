import { useState, useRef, useEffect, Fragment } from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import { Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import ReservTimeList from "../components/ReservTimeList";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import ReservRoomList from "../components/ReservRoomList";
const mainApi = new HttpMainApi();
interface propsType {
  userState: userState;
}
interface ReservationDetail {
  deviceNum: string;
  reservTime: string;
  reservDt: string;
  userName: string;
  userId: string;
  inputDt: string;
}
interface Reservation {
  sk: string;
  pk: string;
  reservations: { [key: string]: ReservationDetail };
}

const ReservationScreen = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const useTimeValues: string[] = ["18:00", "19:00", "20:00", "21:00", "22:00"];
  const [holidays, setHolidays] = useState<string[]>([]);
  const [stopDay, setStopDay] = useState<string[]>([]);
  const [stopDayMemo, setStopDayMemo] = useState("");
  const today = new Date()
    .toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/. /g, "-")
    .replace(".", "");
  function createMarkup(text: string): JSX.Element[] {
    const paragraphs = text.split(/<br><\/br>/gi);
    return paragraphs.map((paragraph, index) => (
      <Fragment key={index}>
        {paragraph}
        {index < paragraphs.length - 1 && <br />}
      </Fragment>
    ));
  }

  const isStopday = stopDay.includes(today);
  const isHoliday = holidays.includes(today);

  const get_holiday_list = async () => {
    setIsLoading(true);
    const pk = "COMMUNITY/CLOSE";
    const sk = "HOLIDAY";
    const param: any = {
      pk: pk,
      sk: sk,
    };
    // console.log("param : ", param);
    const res = await mainApi.get_holiday_list(param);
    if (res.code === "200") {
      setHolidays(res.response.result[0]["holidays"]);
      setStopDay(res.response.result[0]["temporary"]);
      setStopDayMemo(res.response.result[0]["memo"]);
      // console.log("holidays:", res.response.result[0]["holidays"]);
      // console.log("temporary:", res.response.result["temporary"]);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  type ValuePiece = Date | null;
  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const [value, onChange] = useState<Value>(new Date());
  const isPastDate = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 오늘 날짜의 시간을 00:00:00으로 설정
    return date < today; // 오늘보다 이전 날짜인 경우 true 반환
  };
  const isToday = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date.getTime() === today.getTime();
  };
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    return `${year}${formattedMonth}${formattedDay}`;
  };

  const formatDate2 = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    return `${year}년 ${formattedMonth}월 ${formattedDay}일`;
  };
  function formatDate3(date: Date) {
    const year = date.getFullYear();
    // getMonth()는 0부터 시작하므로 1을 더해줍니다.
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const fn_onClickDay = (newDate: Date) => {
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();
    // 두 자리수로 포매팅
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    // YYYYMMDD 형식으로 결합
    const formattedDate = `${year}${formattedMonth}${formattedDay}`;
    const formattedDate2 = `${year}년 ${formattedMonth}월 ${formattedDay}일`;
    setSelectDate(formattedDate);
    setSelectformatDate(formattedDate2);
  };

  const buttons = [
    { key: "타석1", label: "타석1" },
    { key: "타석2", label: "타석2" },
    { key: "ROOM", label: "ROOM (유료)" },
  ];
  function isDisabled(date: Date): boolean {
    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    const isFirstDayOfMonth = dayOfMonth === 1;
    const isMayFirst = date.getMonth() === 4 && dayOfMonth === 1;
    const isJanuaryFirst = date.getMonth() === 0 && dayOfMonth === 1;
    const isMarFirst = date.getMonth() === 2 && dayOfMonth === 1;
    const isOctFirst = date.getMonth() === 9 && dayOfMonth === 1; //2024년 10월 1일 임시 추가
    const formattedDate = formatDate3(date);
    // 매월 1일이 토요일 또는 일요일인 경우
    if (
      isFirstDayOfMonth &&
      (dayOfWeek === 6 ||
        dayOfWeek === 0 ||
        isMayFirst ||
        isJanuaryFirst ||
        isMarFirst ||
        isOctFirst)
    ) {
      return false; // 휴무일이 아님
    }

    // 매월 1일이 아닌 경우
    if (!isFirstDayOfMonth) {
      return holidays.includes(formattedDate);
    }

    // 매월 1일이지만 토요일 또는 일요일이 아닌 경우

    return true; // 휴무일
  }

  function findFirstValidDate() {
    const today = new Date();
    let date = new Date(today);
    let tempDate = "";
    if (isDisabled(date)) {
      tempDate = "";
    } else {
      tempDate = formatDate(date);
    }

    return tempDate;
  }

  const [selectedDevice, setSelectedDevice] = useState(buttons[0].key);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectDate, setSelectDate] = useState(findFirstValidDate());
  const [selectformatDate, setSelectformatDate] = useState(
    formatDate2(new Date())
  );
  const handleButtonClick = (buttonKey: any) => {
    setSelectedTime(null);
    setSelectedTimes([]);
    setSelectedDevice(buttonKey);
    setHole("60");
    setPeople("1");
    setUseTime(60);
    setMaxTime(2);
  };
  const handleTimeButtonClick = (time: any) => {
    setSelectedTime(time);
  };

  const reservation_save = async () => {
    const tempDate = await findFirstValidDate();
    if (tempDate === "" || tempDate === undefined || tempDate === null) {
      toastRef.current?.toast("커뮤니티센터 휴무일입니다.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (golfMember === false) {
      toastRef.current?.toast(
        "[회원전용]골프연습장 신청 후 예약이 가능합니다.",
        "error",
        4000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
      return;
    }
    if (hourCheck === true) {
      const selectTimeResult = useTimeValues.includes(selectedTime || "00:00");
      if (selectTimeResult == true) {
        toastRef.current?.toast(
          "[안내]18시 이후는 1시간 예약만 가능합니다.",
          "error",
          4000,
          {
            vertical: "top",
            horizontal: "center",
          }
        );
        return;
      }
    }
    if (
      selectedDevice === "" ||
      selectedDevice === undefined ||
      selectedDevice === null
    ) {
      toastRef.current?.toast("타석을 선택하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (tempDate === "" || tempDate === undefined || tempDate === null) {
      toastRef.current?.toast("날짜를 선택하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (
      selectedTime === "" ||
      selectedTime === undefined ||
      (selectedTime === null && selectedTimes.length === 0)
    ) {
      toastRef.current?.toast("시간을 선택하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (selectedTimes.length > 0) {
      if (selectedTimes.length !== maxTime) {
        alert(
          useTime +
            "분이 필요합니다.\n선택된 시간과 최대 예약 가능 시간이 일치하지 않습니다."
        );
        return;
      }

      const convertTimeToMinutes = (time: string) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      };

      // 선택된 시간을 분 단위로 정렬
      const sortedTimes = [...selectedTimes].sort(
        (a, b) => convertTimeToMinutes(a) - convertTimeToMinutes(b)
      );

      // 연속된 시간인지 확인
      const isConsecutive = sortedTimes.every((time, index, array) => {
        if (index === 0) return true; // 첫 번째 시간은 항상 '연속적'
        return (
          convertTimeToMinutes(time) -
            convertTimeToMinutes(array[index - 1]) ===
          30
        );
      });

      if (!isConsecutive) {
        alert("선택된 시간이 연속되지 않습니다.");
        return;
      }
    }
    let alertMemo = "";
    if (selectedDevice === "ROOM") {
      alertMemo =
        "예약 하시겠습니까?\n[주의]게임 시작 1시간 전부터 취소가 불가능합니다.";
    } else {
      alertMemo = "타석을 예약 하시겠습니까?";
    }
    const confirmCancel = window.confirm(alertMemo);
    if (confirmCancel) {
      const { formattedDateTime, formathourspk, formatmonthpk } =
        getFormattedDateTime();
      const userId = props.userState.userId;
      const userAddr = props.userState.readAddress;
      const userName = props.userState.userName;
      const pk = `${selectDate}/SCREEN`;
      const mypk = `${formatmonthpk}/SCREEN`;
      let TempCommunityPrice = communityPrice9;
      let screenHole = "";

      if (selectedDevice !== "ROOM") {
        TempCommunityPrice = 0;
        screenHole = selectedDevice;
      } else {
        if (selectedTimes.length > 0) {
          if (hole === "30") {
            screenHole = "9홀";
            TempCommunityPrice = communityPrice9 * selectedTimes.length;
          } else {
            screenHole = "18홀";
            TempCommunityPrice = Math.round(
              (communityPrice18 * selectedTimes.length) / 2
            );
          }
        }
      }
      const param: any = {
        pk: pk,
        sk: selectedDevice,
        option: selectedTime,
        userId: userId,
        userAddr: userAddr,
        userName: userName,
        inputDt: formattedDateTime,
        reservDt: selectformatDate,
        my_pk: mypk,
        my_sk: selectDate + formathourspk,
        communityPrice: TempCommunityPrice,
        info_01: screenHole,
      };
      const res = await mainApi.put_month_reserv_data(param);
      if (res.code === "200") {
        alert("예약이 완료되었습니다.");
        navigate("/reservationscreenend");
      } else if (res.code === "301") {
        alert("이미 예약된 시간입니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  const [times, setTimes] = useState<string[]>([]);

  const extractReservationTimes = (
    reservationData: Reservation[]
  ): string[] => {
    return reservationData.flatMap((item) =>
      Object.keys(item.reservations).flatMap((time) => time.split(","))
    );
  };

  const get_day_reservation = async () => {
    setIsLoading(true);
    const pk = `${selectDate}/SCREEN`;
    const sk = selectedDevice;
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_day_reservation(param);
    if (res.code === "200") {
      const extractedTimes = extractReservationTimes(res.response.result);
      setTimes(extractedTimes);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const [hourCheck, setHourCheck] = useState(false);
  const one_hour_check = async () => {
    const formatmonthpk = selectDate.substring(0, 6);
    const userId = props.userState.userId;
    const pk = userId;
    const sk = selectDate;
    const param: any = {
      pk: pk,
      sk: sk,
      reservFilter: `${formatmonthpk}/SCREEN`,
      use_time_values: useTimeValues,
    };

    const res = await mainApi.get_one_hour_check(param);
    if (res.code === "200") {
      setHourCheck(true);
    } else if (res.code === "400") {
      setHourCheck(false);
    }
  };

  const [golfMember, setGolfMember] = useState(true);
  const get_my_reservation = async () => {
    setIsLoading(true);

    const formatmonthpk = selectDate.substring(0, 6);
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: `${formatmonthpk}/GOLF`,
    };

    const res = await mainApi.get_my_reservation_diff_check(param);
    if (res.code === "200") {
      setGolfMember(true);
    } else if (res.code === "400") {
      setGolfMember(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    get_holiday_list();
    get_day_reservation();
    get_my_reservation();
    one_hour_check();
  }, [selectedDevice, selectDate]);

  //ROOM
  const [people, setPeople] = useState("1");
  const [hole, setHole] = useState("60");
  const [useTime, setUseTime] = useState(60);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [maxTime, setMaxTime] = useState(2);
  const handleChange = (event: SelectChangeEvent) => {
    setPeople(event.target.value as string);
    const peopleString = event.target.value as string;
    const peopleNumber = parseInt(peopleString, 10);
    const holeNumber = parseInt(hole, 10);
    setUseTime(peopleNumber * holeNumber);
    setMaxTime(Math.ceil((peopleNumber * holeNumber) / 30));
    setSelectedTimes([]);
  };

  const handleHoleChange = (event: SelectChangeEvent) => {
    setHole(event.target.value as string);
    const holeString = event.target.value as string;
    const holeNumber = parseInt(holeString, 10);
    const peopleNumber = parseInt(people, 10);
    setUseTime(peopleNumber * holeNumber);
    setMaxTime(Math.ceil((peopleNumber * holeNumber) / 30));
    setSelectedTimes([]);
  };

  // 시간 버튼 클릭 핸들러입니다.
  const handleRoomButtonClick = (timeValue: any) => {
    setSelectedTimes((prevTimes) => {
      if (prevTimes.length >= maxTime && !prevTimes.includes(timeValue)) {
        alert("최대 예약 시간을 초과했습니다.");
        return prevTimes; // 현재 상태를 유지
      }
      const newTimes = prevTimes.includes(timeValue)
        ? prevTimes.filter((time) => time !== timeValue)
        : [...prevTimes, timeValue];

      // 새로운 배열을 문자열로 변환하여 setSelectedTime에 설정
      const timeTemp = newTimes.join(",");
      setSelectedTime(timeTemp);

      return newTimes;
    });
  };

  const [communityPrice9, setCommunityPrice9] = useState(0);
  const [communityPrice18, setCommunityPrice18] = useState(0);
  const get_price9 = async () => {
    const pk = "COMMUNITY/ACCOUNT";
    const sk = "스크린9홀";
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_price(param);
    if (res.code === "200") {
      setCommunityPrice9(res.response.result[0]["PRICE"]);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };
  const get_price18 = async () => {
    const pk = "COMMUNITY/ACCOUNT";
    const sk = "스크린18홀";
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_price(param);
    if (res.code === "200") {
      setCommunityPrice18(res.response.result[0]["PRICE"]);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };
  const [reserv_am, setReserv_am] = useState<string[]>([]);
  const [reserv_pm, setReserv_pm] = useState<string[]>([]);
  const [reserv_room, setReserv_room] = useState<string[]>([]);

  const get_date_time = async () => {
    // 화요일부터 금요일까지
    setReserv_am([
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
    ]);
    setReserv_pm([
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
    ]);
    setReserv_room([
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
    ]);
  };

  useEffect(() => {
    get_price9();
    get_price18();
    get_date_time();
  }, []);

  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minWidth: "360px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 300,
              textAlign: "left",
              margin: "auto",
              mt: 2,
              ml: 3,
            }}
          >
            예약정보
          </Typography>
        </Box>

        <hr
          style={{ borderColor: "#ddd", borderWidth: "0.3px", width: "100%" }}
        />

        <Box
          sx={{
            width: "90%",
            maxWidth: isMobile ? "100%" : "40rem",
            margin: "auto",
          }}
        >
          {/* 오늘 이후만 예약 가능
          <Calendar
            onChange={onChange}
            onClickDay={fn_onClickDay}
            value={value}
            className="custom-calendar"
            tileDisabled={({ date, view }) =>
              view === "month" && isPastDate(date)
            }
          /> */}
          {/* 오늘만 예약가능 */}
          <Calendar
            onChange={onChange}
            onClickDay={fn_onClickDay}
            value={value}
            className="custom-calendar"
            tileDisabled={
              ({ date, view }) =>
                !isToday(date) || // 오늘이 아닌 날짜 비활성화
                isDisabled(date)
              // 공휴일 비활성화, 단, 오늘 날짜는 예외 처리
            }
          />
        </Box>

        <hr
          style={{ borderColor: "#ddd", borderWidth: "0.3px", width: "100%" }}
        />

        <ButtonGroup size="large" aria-label="large button group">
          {buttons.map((button) => (
            <Button
              key={button.key}
              style={{
                borderColor: "#2E3341",
                color: selectedDevice === button.key ? "#ffffff" : "#2E3341",
                backgroundColor: selectedDevice === button.key ? "#2E3341" : "",
              }}
              onClick={() => handleButtonClick(button.key)}
            >
              {button.label}
            </Button>
          ))}
        </ButtonGroup>

        {isStopday ? (
          <Box
            sx={{
              width: "90%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="button"
              gutterBottom
              sx={{
                textAlign: "left",
                margin: "auto",
                whiteSpace: "pre-line",
                color: "red", // 붉은색 강조
              }}
            >
              * 골프연습장 점검중입니다.
            </Typography>
            <Alert
              severity="error"
              icon={false}
              sx={{
                textAlign: "left", // 텍스트를 좌측으로 정렬
                alignItems: "flex-start", // 내용을 상단에 정렬
              }}
            >
              {createMarkup(stopDayMemo)}
            </Alert>
          </Box>
        ) : isHoliday ? (
          <Box
            sx={{
              width: "90%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="button"
              gutterBottom
              sx={{
                textAlign: "left",
                margin: "auto",
                whiteSpace: "pre-line",
                color: "red",
              }}
            >
              * 오늘은 커뮤니티센터 휴무일입니다.
            </Typography>
            <Alert
              severity="error"
              icon={false}
              sx={{
                textAlign: "left", // 텍스트를 좌측으로 정렬
                alignItems: "flex-start", // 내용을 상단에 정렬
              }}
            >
              매월 1일은 휴무입니다. <br></br>만약 1일이 공휴일인 경우, 다음
              근무일이 휴무일이 됩니다.
              <br></br>휴무일에는 시설물 정비를 진행합니다.
            </Alert>
          </Box>
        ) : (
          <Box
            sx={{
              width: "90%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="button"
              gutterBottom
              sx={{
                textAlign: "left",
                margin: "auto",
                whiteSpace: "pre-line",
              }}
            >
              * 스크린 타석과 ROOM은 당일 예약만 가능합니다.
            </Typography>
            <Alert
              severity="info"
              icon={false}
              sx={{
                textAlign: "left", // 텍스트를 좌측으로 정렬
                alignItems: "flex-start", // 내용을 상단에 정렬
              }}
            >
              운영시간은 평일과 주말 모두 05:00부터 23:00까지이며, 매월 1일은
              휴무입니다. <br></br>만약 1일이 공휴일인 경우, 다음 근무일이
              휴무일이 됩니다.
              <br></br>휴무일에는 시설물 정비를 진행합니다.
            </Alert>
          </Box>
        )}

        {selectedDevice === "ROOM" ? (
          <>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">인원</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={people}
                    label="인원"
                    onChange={handleChange}
                    sx={{ height: 40 }}
                  >
                    <MenuItem value={"1"}>1명</MenuItem>
                    <MenuItem value={"2"}>2명</MenuItem>
                    <MenuItem value={"3"}>3명</MenuItem>
                    <MenuItem value={"4"}>4명</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">홀</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={hole}
                    label="홀"
                    onChange={handleHoleChange}
                    sx={{ height: 40 }}
                  >
                    <MenuItem value={"30"}>9홀</MenuItem>
                    <MenuItem value={"60"}>18홀</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 20 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontWeight: 300,
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  {useTime} 분
                </Typography>
              </Box>
            </Box>
            <ReservRoomList
              userState={props.userState}
              reserv_time={reserv_room}
              handleRoomButtonClick={handleRoomButtonClick}
              selectedTimes={selectedTimes}
              chunkNum={4}
              times={times}
            />
          </>
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 300,
                  textAlign: "left",
                  margin: "auto",
                  ml: 3,
                }}
              >
                오전
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <ReservTimeList
                userState={props.userState}
                reserv_time={reserv_am}
                handleTimeButtonClick={handleTimeButtonClick}
                selectedTime={selectedTime}
                chunkNum={4}
                times={times}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 300,
                  textAlign: "left",
                  margin: "auto",
                  ml: 3,
                }}
              >
                오후
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <ReservTimeList
                userState={props.userState}
                reserv_time={reserv_pm}
                handleTimeButtonClick={handleTimeButtonClick}
                selectedTime={selectedTime}
                chunkNum={4}
                times={times}
              />
            </Box>
          </>
        )}
        <Stack
          direction="row"
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            width: "95%", // 전체 너비를 사용하도록 설정
            height: "4em",
            marginTop: "3em",
          }}
        >
          <Button
            sx={{
              borderRadius: "10px",
              border: "1px solid #ccc",
              width: "100%",
            }}
            color="primary"
            variant="contained"
            endIcon={<CheckIcon />}
            onClick={() => reservation_save()}
          >
            <Typography variant="h6">예약 하기</Typography>
          </Button>
        </Stack>
      </Stack>

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default ReservationScreen;
