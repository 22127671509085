import { useState, useRef } from "react";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { userState } from "../../interface/MainInterface";
import { useNavigate } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

interface propsType {
  userState: userState;
}
interface DataType {
  content1: string;
  content2: string;
  content3: string;
}

const itemData = [
  {
    img: "/images/reserv_02.jpeg",
    title: "헬스장",
    author: "운영시간 : 05시 ~ 23시",
    rows: 1,
    cols: 1,
    featured: true,
    content3: "/reservationhealth",
  },
  {
    img: "/images/reserv_01.jpeg",
    title: "골프 연습장",
    author: "운영시간 : 05시 ~ 23시",
    content3: "/reservationgolf",
  },

  {
    img: "/images/reserv_03.jpeg",
    title: "독서실",
    author: "운영시간 : 06시 ~ 24시",
    content3: "/reservationstudy",
  },
  {
    img: "/images/reserv_04.jpeg",
    title: "락커 신청",
    author: "운영시간 : 시설물 이용시간",
    content3: "/reservationlocker",
  },
];

const Reservation = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleReservationClick = (content3: string) => {
    // 클릭 이벤트가 발생했을 때 실행할 코드
    navigate(content3);
  };

  return (
    <>
      <Stack
        spacing={2} // This controls the space between child components
        alignItems="center" // Align items to the center
        justifyContent="center" // Center items along the main axis
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
        }}
      >
        <ImageList cols={1}>
          {itemData.map((item) => (
            <ImageListItem
              key={item.img}
              cols={item.cols || 1}
              onClick={() => handleReservationClick(item.content3)}
            >
              <img
                srcSet={`${item.img}`}
                src={`${item.img}`}
                alt={item.title}
                loading="lazy"
                style={{
                  width: "100%", // 이미지 너비를 부모 요소에 맞춤
                  minWidth: "360px", // 최소 너비 설정
                  height: "auto", // 높이를 자동으로 설정하여 비율 유지
                }}
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default Reservation;
