import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CryptoJS from "crypto-js";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
//IoT start
import { v4 as uuidv4 } from "uuid";
import * as AWS from "aws-sdk";
import { appConfig } from "../../config/Config";
import moment from "moment";
import * as Paho from "paho-mqtt";
//IoT end
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface propsType {
  userState: userState;
}
const mainApi = new HttpMainApi();

const CommunityInfo = (props: propsType) => {
  const toastRef: any = useRef();

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box
          sx={{
            position: "relative", // Flexbox를 사용하여 내용을 중앙에 배치
            display: "inline-block",
            maxWidth: "100%",
          }}
        >
          <img
            src="/images/price_03.png"
            alt="manual"
            style={{
              maxWidth: "100%", // Box 내에서 최대 너비 100%
              height: "auto", // 이미지의 원래 비율을 유지
            }}
          />
        </Box>
      </Stack>
      <Toast ref={toastRef} />
    </>
  );
};

export default CommunityInfo;
